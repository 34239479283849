import React, { useEffect, useState } from "react";
import style from "./LoginForm/LoginForm.module.css";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options_top,
  success_options_top,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../Common/helpers.js";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

const ForgotPassword = (props) => {
  const [openSnackbarError] = useSnackbar(error_options_top);
  const [openSnackbarSuccess] = useSnackbar(success_options_top);
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    const isProduction = process.env.ENV === 'production';
    const isPatientPage = window.location.href.indexOf("patient") !== -1;
    
    if (isProduction || isPatientPage) {
      setDisableButton(true);
    }
    
  }, []);
  //======================= FORGOT PASSWORD ==========================//

  const PASSWORD_RESET = gql`
    mutation sendPasswordResetEmail($resetEmail: String!) {
      sendPasswordResetEmail(input: { email: $resetEmail }) {
        success
        errors
      }
    }
  `;

  const [passwordResetReq, { loadingVerify }] = useMutation(PASSWORD_RESET, {
    onCompleted({ sendPasswordResetEmail }) {
      if (sendPasswordResetEmail.success) {
        openSnackbarSuccess(t("forgotPassword.passwordResetEmailSent"));
      } else {
        let errors = sendPasswordResetEmail?.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let emailErrors = errors.email;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in emailErrors) {
          let e = emailErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("passwordResetReq error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const RESEND_VERIFICATION = gql`
    mutation resendActivationEmail($resetEmail: String!) {
      resendActivationEmail(input: { email: $resetEmail }) {
        success
        errors
      }
    }
  `;

  const [sendVerifyEmail, { loadingReset }] = useMutation(RESEND_VERIFICATION, {
    onCompleted({ resendActivationEmail }) {
      if (resendActivationEmail.success) {
        openSnackbarSuccess(t("forgotPassword.verificationEmailSent"));
      } else {
        let errors = resendActivationEmail?.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let emailErrors = errors.email;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in emailErrors) {
          let e = emailErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("passwordResetReq error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const initialValuesReset = {
    email: "",
  };

  const onSubmitReset = (values, { resetForm }) => {
    if (props.verifyEmail === true) {
      sendVerifyEmail({ variables: { resetEmail: values.email } });
    } else {
      passwordResetReq({ variables: { resetEmail: values.email } });
    }
    resetForm({ values: "" });
  };

  const formikReset = useFormik({
    initialValues: initialValuesReset,
    onSubmit: onSubmitReset,
  });

  return (
    <Form onSubmit={formikReset.handleSubmit}>
      <Form.Group as={Row} className="mb-4">
        <Form.Label column sm={3} className={"text-white text-right"}>
          {t("forgotPassword.email")}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="email"
            name="email"
            onChange={formikReset.handleChange}
            value={formikReset.values.email || " "}
            autoComplete="username"
            required
            id="forgot-pass-email-test"
          />
        </Col>
      </Form.Group>
      <Button
        variant="link"
        onClick={() => props.history.push("/")}
        block
        className="forgot_password"
        id="forgot-pass-back-test"
      >
        {t("forgotPassword.backToLogin")}
      </Button>
      <Form.Group as={Row}></Form.Group>
      <Form.Group as={Row}>
        <Col className={style.login_form__submit}>
          <Button
            disabled={loadingReset || loadingVerify }
            className="login_form__button col-lg-3 col-sm-12"
            type="submit"
            id="forgot-pass-reset-test"
          >
            {loadingReset || loadingVerify ? "Loading ..." : t("submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withRouter(ForgotPassword);
