import React, { useEffect, useState } from "react";
import style from "./LoginForm.module.css";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { isLoggedInVar, userDetailsVar } from "../../../cache/cache.js";
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
  error_options_top,
} from "../../../Common/helpers.js";
import { useReactiveVar } from "@apollo/client";
import { appLanguageVar } from "../../../cache/cache.js";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
const jwt = require('jsonwebtoken');

const LoginForm = (props) => {
  const { setLangChangeLoader } = props;
  const [openSnackbarError] = useSnackbar(error_options_top);
  const appLanguage = useReactiveVar(appLanguageVar);
  const [isUsername, setIsUsername] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [submitToken, setSubmitToken] = useState(false);

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  //=======================LOGIN==========================//

  useEffect(() => {
    const isProduction = process.env.ENV === "production";
    const isPatientPage = window.location.href.indexOf("patient") !== -1;

    if (isProduction || isPatientPage) {
      setDisableLogin(true);
    }
  }, []);

  const LOGIN_EMAIL = gql`
    mutation tokenAuth($email: String!, $password: String!, $otpToken: String) {
      tokenAuth(
        input: { email: $email, password: $password, otpToken: $otpToken }
      ) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          email
          username
          isStaff
          verified
          pk
          isSuperuser
          leaveRequests {
            edges {
              node {
                id
                startDate
                endDate
                totalDays
                status
                leaveType
                created
                description
                user {
                  firstName
                  lastName
                  phone
                  id
                  hr {
                    position {
                      name
                    }
                    department {
                      name
                    }
                    numberAnnualLeaves
                    consumedSickLeaves
                    remainingAnnualLeaves
                  }
                }
              }
            }
          }
          canEditAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          canViewAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          doctor {
            id
            identifier
            doctorId
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
            discount
            patientId
          }
        }
      }
    }
  `;

  const LOGIN_USERNAME = gql`
    mutation tokenAuth(
      $username: String!
      $password: String!
      $otpToken: String
    ) {
      tokenAuth(
        input: { username: $username, password: $password, otpToken: $otpToken }
      ) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          username
          isStaff
          pk
          leaveRequests {
            edges {
              node {
                id
                startDate
                endDate
                totalDays
                status
                leaveType
                created
                description
                user {
                  firstName
                  lastName
                  phone
                  id
                  hr {
                    position {
                      name
                    }
                    department {
                      name
                    }
                    numberAnnualLeaves
                    consumedSickLeaves
                    remainingAnnualLeaves
                  }
                }
              }
            }
          }
          canEditAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          canViewAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
            discount
          }
        }
      }
    }
  `;

  const LOGIN = isUsername ? LOGIN_USERNAME : LOGIN_EMAIL;
  const registerSuccess = props.location?.state?.registerSuccess;
  const [showModal, setShowModal] = useState(registerSuccess ? true : false);
  const [tokenAuth, { loading }] = useMutation(LOGIN, {
    onCompleted({ tokenAuth }) {
      if (tokenAuth.success === false) {
        setEmailValue("");
        setPasswordValue("");
        let errors = tokenAuth?.errors.nonFieldErrors;
        setSubmitToken(false);
        for (let i in errors) {
          let error = errors[i];

          let message = error.message;

          // check if code = not_verified
          const code = error?.code;
          if (code === "not_verified") {
            // message = (
            //   <Link to="/resend/verification/email" style={{ color: "#fff" }}>
            //     {t("patientLogin.verifyEmailError")}
            //   </Link>
            // );
            setShowModal(true);
          } else if (code === "invalid_credentials") {
            openSnackbarError(message, [SNACK_DURATION]);
          }
        }
      } else if (tokenAuth.success === true) {
        setSubmitToken(true);
        const { history } = props;
        localStorage.setItem("token", tokenAuth.token);
        localStorage.setItem("refreshToken", tokenAuth.refreshToken);
        localStorage.setItem("user", JSON.stringify(tokenAuth.user));
        // const loginTokenExpiry = new Date(Date.now() + 1000 * 5 * 60);
        // localStorage.setItem("loginTokenExpiry", loginTokenExpiry);
        // const newExpiration = new Date(Date.now() + 1000 * 19 * 60);
        // localStorage.setItem("sessionExpiration", newExpiration);
        const token = localStorage.getItem("token");
        console.log("-----------------",token);
        const decodedToken = jwt.decode(token);
        console.log("Decoded Token: ", decodedToken);
        const sessionExpirationFromToken = new Date(decodedToken.exp * 1000);
        console.log("sessionExpirationFromToken----------",sessionExpirationFromToken);
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        const lastDayOfMonth = new Date(
          currentYear,
          currentMonth + 1,
          0,
          23,
          59,
          59
        );

        const loginTokenExpiryTime = 1000 * 5 * 60;
        const loginTokenExpiryDate = new Date(
          Date.now() + loginTokenExpiryTime
        );
        const loginTokenExpiry =
          loginTokenExpiryDate < lastDayOfMonth
            ? loginTokenExpiryDate
            : lastDayOfMonth;
        localStorage.setItem("loginTokenExpiry", loginTokenExpiry);

        // const sessionExpiryTime = 1000 * 19 * 60;
        // const sessionExpiryDate = new Date(Date.now() + sessionExpiryTime);
        // const sessionExpiration = sessionExpiryDate < lastDayOfMonth ? sessionExpiryDate : lastDayOfMonth;
        const sessionExpiration = sessionExpirationFromToken < lastDayOfMonth ? sessionExpirationFromToken : lastDayOfMonth;
        localStorage.setItem("sessionExpiration", sessionExpiration);
        userDetailsVar(JSON.stringify(tokenAuth.user));
        isLoggedInVar(true);
        if (tokenAuth.user.isStaff) {
          localStorage.setItem("is_staff", true);
          if (appLanguage === "ar") {
            setLangChangeLoader(true);
            document.body.lang = "en";
            document.body.dir = "ltr";
            i18n.changeLanguage("en");
            localStorage.setItem("appLanguage", "en");
            setTimeout(() => {
              window.location = window.location.href;
            }, 500);
          }
          if (
            props.location &&
            props.location.state &&
            props.location.state.from
          ) {
            history.push(props.location.state.from.pathname);
          } else {
            history.push("/dashboard");
          }
        } else if (!tokenAuth.user.isStaff) {
          localStorage.setItem("isPatientPortal", true);
          if (
            tokenAuth.user.patient &&
            tokenAuth.user.patient.profileComplete
          ) {
            if (
              props.location &&
              props.location.state &&
              props.location.state.from
            ) {
              history.push(props.location.state.from.pathname);
            } else {
              history.push("/patientPortal/dashboard");
            }
            // }
          } else {
            history.push("/patientPortal/dashboard");
          }
          localStorage.setItem("is_staff", false);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setTag(
        "ERROR CODE statusCode",
        { code: e?.networkError?.statusCode } + ""
      );
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("tokenAuth error " + e);
      }
      setSubmitToken(false);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleResendVerification = () => {
    handleCloseModal();
    window.location.href = "/resend/verification/email";
  };

  const handleCloseModal = () => {
    setShowModal(false);
    props.history.replace({
      state: { ...props.location.state, registerSuccess: false },
    });
  };

  const handleResend = () => {
    if (registerSuccess) {
      return (
        <Modal show={showModal} onHide={() => handleCloseModal()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("auth.successStatus")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="5x"
                color="#28a745"
                style={{
                  display: "block",
                  margin: "auto",
                }}
              />
            </p>
            <p>{t("auth.registrationSuccessMessage")}</p>
            <p>
              <a
                href="#"
                onClick={handleResendVerification}
                style={{ color: "#007bff", cursor: "pointer" }}
              >
                {t("auth.resendVerificationEmail")}
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal()}>
              {t("modal.close")}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <Modal show={showModal} onHide={() => handleCloseModal()} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("auth.errorStatus")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("patientLogin.verifyEmailError")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal()}>
            {t("modal.close")}
          </Button>
          <Button variant="primary" onClick={handleResendVerification}>
            {t("auth.resendVerificationEmail")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const initialValues = {
    email: "",
    password: "",
    otpToken: "",
  };

  const isEmpty = (str) => {
    return !str || str.length === 0;
  };

  const onSubmit = (values, { resetForm }) => {
    if (isEmpty(emailValue.trim())) {
      alert("Email required.");
      return;
    }
    if (isEmpty(passwordValue.trim())) {
      alert("Password required.");
      return;
    }

    let token = {};
    if (values.otpToken) {
      token = {
        otpToken: values.otpToken,
      };
    }

    if (window.location.hostname.startsWith("patient") || submitToken) {
      if (emailValue.includes("@")) {
        tokenAuth({
          variables: { email: emailValue, password: passwordValue, ...token },
        });
      } else {
        setIsUsername(true);
        setTimeout(() => {
          tokenAuth({
            variables: { email: emailValue, password: passwordValue, ...token },
          });
        }, 300);
      }
    } else {
      setSubmitToken(true);
    }

    resetForm({ values: "" });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const handleChangeEmail = (event) => {
    setEmailValue(event.target.value);
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    setPasswordValue(password);
    const isValid = password.length >= 6;
    setIsPasswordValid(isValid);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  let passwordClass;
  if (passwordValue === "") {
    passwordClass = "emptyPassword";
  } else {
    passwordClass = isPasswordValid
      ? style.validPassword
      : style.invalidPassword;
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Col xs={12} md={11} lg={11}>
          <div className={!submitToken ? "" : "d-none"}>
            <Form.Group as={Row} className="mb-4">
              <Form.Label
                column
                xs={12}
                sm={3}
                className={"text-white text-right"}
              >
                {t("patientLogin.email")}
              </Form.Label>
              <Col xs={12} sm={9}>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={handleChangeEmail}
                  value={emailValue}
                  autoComplete="username"
                  id="register-login-email-test"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label
                column
                xs={12}
                sm={3}
                className={"text-white text-right"}
              >
                {t("patientLogin.password")}
              </Form.Label>
              <Col xs={12} sm={9}>
                <Form.Control
                  autoComplete="new-password"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="register-login-password-test"
                  onChange={handleChangePassword}
                  value={passwordValue}
                  // className={`form-control ${passwordClass}`}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle-btn"
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </Col>
            </Form.Group>
            <Button
              variant="link"
              onClick={() => props.history.push("/register")}
              block
              className="forgot_password"
              id="register-test"
            >
              {" "}
              {t("patientLogin.register")}
            </Button>
            <Button
              variant="link"
              onClick={() => props.history.push("/forgot/password")}
              block
              className="forgot_password"
              id="register-forgot-pass"
            >
              {" "}
              {t("patientLogin.forgotPassword")}
            </Button>
            <Form.Group as={Row}></Form.Group>
          </div>
          <div className={submitToken ? "" : "d-none"}>
            <Form.Group as={Row} className="mb-4">
              <Form.Label
                column
                xs={12}
                sm={3}
                className={"text-white text-right"}
              >
                {t("patientLogin.otpForm")}
              </Form.Label>
              <Col xs={12} sm={9}>
                <Form.Control
                  type="text"
                  name="otpToken"
                  onChange={formik.handleChange}
                  value={formik.values.otpToken}
                  autoComplete="username"
                  // required
                />
              </Col>
            </Form.Group>
          </div>

          <Form.Group as={Row}>
            <Col className={style.login_form__submit}>
              <Button
                disabled={loading}
                className="login_form__button col-lg-3 col-sm-12"
                type="submit"
                id="register-login-submit-test"
              >
                {loading ? "Loading ..." : t("patientLogin.submit")}
              </Button>
            </Col>
          </Form.Group>
        </Col>
      </Form>

      {showModal && handleResend()}
    </>
  );
};

export default withRouter(LoginForm);
